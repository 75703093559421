@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Cardo:regular,italic,bold|Source+Sans+Pro:bold');

$heading-font-family: 'Source Sans Pro', sans-serif;
$heading-font-size:   16pt;
$heading-line-height: 1.5;
$heading-font-weight: bold;

$base-font-family: 'Cardo', serif;
$base-font-size:   16pt;
$base-line-height: 1.5;

$footer-font-family: 'Cardo', serif;

$small-font-size:  0.875em;
$vsmall-font-size: 0.66em;
$spacing-unit:     1.5em;

$grey-vlight:#fefefe;
$grey-light: #ccc;
$grey:       #888;
$grey-dark:  #444;
$grey-vdark: #222;
$grey-transparent: rgba(0, 0, 0, 0.8);
$blue:       #0091ed;
$orange:     #ca6c18;
$red:        #a61329;
$yellow:     #efab2b;

$background:        $grey-vlight;
$dark-background:   darken($background, 5%);
$background-image:  url("banderole.svg");
$background-image-size: 300px;
$text-color:        $grey-vdark;
$text-light-color:  $grey;
$url-color:         $red;
$url-hover-color:   $yellow;
$header-text-color: black;
$header-hover-color:$text-color;
$header-hover-url:  $url-color;
$line-color:        $grey-light;

// Width of the content area
$content-width:    50em;
$content-min-width:15em;
$mobile:           32em;
$desktop:          50em;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "header",
    "mobile-header",
    "base",
    "footer",
    "page",
    "syntax-highlighting",
    "fancy-image",
    "misc";
