.post {
  background-color: rgba(255, 255, 255, 0.97);
  padding: 1em;
}


.post p {
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align: justify;
}


hr {
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
}


abbr, acronym {
  cursor: help;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-style: dotted;
  text-decoration-line: underline;
  text-decoration-style: dotted;
}


.youtube, .google-maps {
    position: relative;
    padding-bottom: 50%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.youtube iframe, .google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}



input, button, textarea {
  width: 100%;
}

input, textarea {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

input, textarea {
  border: 1px solid #a61329;
  border-radius: 4px;
}

button{
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}

textarea {
  resize: none;
}