.site-footer {
  font-family: $footer-font-family;
  font-size: $base_font_size *2;
  font-style: italic;
  color: $text-light-color;
  border-top: 0px solid $line-color;
  padding-top: $spacing-unit /2;
  float: none;
  margin-left: auto;
  margin-right:auto;
  margin-top:  0;
  margin-bottom:$spacing-unit;
  @extend %clearfix;

  a {
    color: $grey-transparent;
    font-size: $small-font-size /1.3;
  }

  a:hover { }
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    background: $grey-transparent;
    color: white;
    text-align: center;
    padding: 1px 20px;
    margin: 3px;
    mix-blend-mode: multiply;
    transform: skew(5deg, -10deg);
    transition: transform 1s;
    }

  span:hover{
    transform: skew(10deg, -14deg);
  }
}
