.site-header {
    border-bottom: 0px solid $line-color;
    position: relative;
    font-family: $heading-font-family;
    font-size:   $heading-font-size;
    font-weight: $heading-font-weight;
    text-transform: uppercase;
    @extend %clearfix;
}

.site-header, .site-header a {
    color: $header-text-color;
}
.site-header a:hover {
    color: $header-hover-color;
    text-decoration: none;
}

.site-title {
    margin: 1em 0 1em 0;
    float: left;
    letter-spacing: 1px;
    color: $background !important;
    background-color : $grey-transparent;
    padding: 10px;
}

.site-nav {
  background: clear;
  text-shadow:
    0px 0px 15px #fff,
    0px 0px 10px #fff,
    0px 0px 5px #fff;
  z-index: 10;

  ul {
    text-align: right;
    float: right;
    letter-spacing: 1px;
    list-style-type: none;
  }

  a { display: block; }

  .menu > li {
    display: inline-block;
    margin: 1em 0 0 0.75em;
    padding: 0;
    position: relative;
    vertical-align: middle;
  }

  .sub-menu {
    border-color: $line-color;
    z-index:2147483647;
    border: 1px solid $line-color;
    background: $background;
    display: none;
    right: -10px;
    padding: 4px 0 3px 0;
    position: absolute;
    text-align: left;
    text-transform: none;
    top: 28px;
    min-width: 200px;
    z-index:999;

    a {
      display: block;
      line-height: 1.2em;
      padding: 4px 10px;
      color: $header-hover-color;
    }

    a:hover { color: $header-hover-url; }

    li {
      border-bottom: 0;
      display: block;
      height: auto;
      margin: 3px 0;
      padding: 0;
      text-align: left;
    }

    li.current-menu-item > a {
      border-left: none;
      border-right: none;
    }
  }
}



.site-nav {
  float: right;

  h1 {
      position: absolute;
      left: -999em;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $header-text-color;
    // Gaps between nav items, but not on the first one
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  li:hover > .sub-menu {
    display: block;
  }

  @include media-query($mobile) {
    position: absolute;
    top: 20px;
    right: 0px;
    border: 1px solid $line-color;
    border-radius: 5px;
    text-align: right;
    background: $background;

    a.menu-icon::before {
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' style='fill:#{$grey-dark}' width='1.25em' height='1.5em' viewBox='0 0 18 15'><g><path d='M18,1.484c0,0.82-0.665,1.484-1.484,1.484H1.484C0.665,2.969,0,2.304,0,1.484l0,0C0,0.665,0.665,0,1.484,0 h15.031C17.335,0,18,0.665,18,1.484L18,1.484z'/><path d='M18,7.516C18,8.335,17.335,9,16.516,9H1.484C0.665,9,0,8.335,0,7.516l0,0c0-0.82,0.665-1.484,1.484-1.484 h15.031C17.335,6.031,18,6.696,18,7.516L18,7.516z'/><path d='M18,13.516C18,14.335,17.335,15,16.516,15H1.484C0.665,15,0,14.335,0,13.516l0,0 c0-0.82,0.665-1.484,1.484-1.484h15.031C17.335,12.031,18,12.696,18,13.516L18,13.516z'/></g></svg>");
    }

    .menu-icon {
      display: block;
      float: right;
      width: 1.5em;
      height: 1.25em;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        width: 1.25em;
        height: 1em;
        vertical-align: middle;
        path {
          fill: $grey-dark;
        }
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
    }
  }
}
