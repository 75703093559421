.captioned-img {
    text-align: center;
    border: none;
    font-size: $vsmall-font-size;
    overflow: hidden;
}

.captioned-img img {
    display: block;
    margin: auto auto;
    padding: 0.5em 0 0.5em 0;
    border: none;
}

.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.alignleft {
    float: left;
    margin-top: 0;
    margin-right: 1em;
    margin-bottom: 0.75em;
}
.alignright {
    float: right;
    margin-top: 0;
    margin-left: 1em;
    margin-bottom: 0.75em;
}
@include media-query($mobile) {
    .alignleft, .alignright {
        display: block;
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}
